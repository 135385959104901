import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useStore } from "../../app/stores/store";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import { history } from '../..';
import SearchBox from "./SearchBox";

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Gewicht Runter Jetzt</title>      
                <meta name="description" content="Plattform mit Themen rund um das Abnehmen, Gewichtsreduzierung und Gesundheit" />
                <meta name="keywords" content="abnehmen, gewicht reduzieren, diät, gesundheit" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Gewichtsreduktion und Gesundheit</h1>
            <AdvertOwnBlock
                title='Abnehmen, Gewichtsreduzierung und Gesundheit'
                content='Viele von uns sind übergewichtig. Häufig gefördert durch den Lebensalltag aber auch oft durch mangelnde Selbstdisziplin zusätzlich unterstützt. Speziell das Bewegungs- und Ernährungsverhalten spielt dabei eine maßgebliche Rolle. In diesem Portal sammeln wir Ratgeber und Tipps, die Ihnen helfen können, Ihr Idealgewicht zu bekommen und zu erhalten.'
                image='/banners/gewichtsreduktion-banner.png'
                imageTarget=''
                targets={[
                    // { linkText: 'Wellness', linkTarget: '/wellness' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            {/* <AdvertsBlock advertCount={1} advertType={1} /> */}

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />

                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/51eZPcvuHUL.jpg'
                    text='Umprogrammierung deines Stoffwechsels-Dauerhafte Gewichtsreduktion'
                    link='https://www.amazon.de/dp/1706754930/ref=as_li_ss_tl?dchild=1&keywords=gewichtsreduktion&qid=1624019285&rnid=1703609031&s=books&sr=1-1&linkCode=ll1&tag=kayomo-21&linkId=ee2a050f3f65605541255e14bd409382&language=de_DE'
                    title='Stoffwechsel Dich schlank'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}